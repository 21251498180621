<template>
  <!-- <div class="regist-main"> -->
  <div>
    <div class="text-home">
      <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
    </div>

    <!-- <el-alert title="恭喜你注册成功" type="success" :hidden="isHiddenSuccess">
    </el-alert> -->
    <!-- <el-alert :title="message" type="error" :hidden="isHiddenError" :closable="isClose">
    </el-alert> -->
    <!-- <el-col :span="6"> </el-col> -->
    <!-- <el-col :span="12"> -->
    <h1 class="brand-info__text">(GBZ188)职业健康检查项目与周期查</h1>
    <el-form :model="form" label-width="100px" class="demo-form">
      <el-form-item label="单位名称">
        <el-input v-model="form.companyName" placeholder="输入单位名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="form.phoneNumber" placeholder="输入11位手机号"></el-input>
        <el-button style="color: dodgerblue" type="text" @click="getCode(form.phoneNumber)">&nbsp;&nbsp;获取验证码
        </el-button>
        <el-input v-model="form.code" placeholder="验证码" style="font-size: 18px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="showDialog = true">添加工种</el-button>
      </el-form-item>
      <el-table :data="jobTypes" style="width: 100%">
        <el-table-column prop="work" label="工种"></el-table-column>
        <el-table-column prop="number" label="人数"></el-table-column>
        <el-table-column prop="riskFactors" label="危险因素">
          <template slot-scope="scope">
            <el-tag v-for="factor in scope.row.riskFactors" :key="factor">{{ factor }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="checkPhase" label="检查阶段">
          <template slot-scope="scope">
            <el-tag v-for="checkPhase in scope.row.checkPhase" :key="checkPhase" type="success">{{ checkPhase
              }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="checkItem" label="检查项目"></el-table-column>
      </el-table>
      <el-form-item>
        <el-button type="success" @click="submitForm">询价</el-button>
        <el-button type="primary" @click="generatePDF">生成项目组合PDF版</el-button>

      </el-form-item>

    </el-form>
    <el-dialog title="添加工种" :visible.sync="showDialog" width="80%">
      <el-form :model="jobForm" label-width="100px" class="demo-form">
        <el-form-item label="工种">
          <el-input v-model="jobForm.work" placeholder="输入工种名称"></el-input>
          <!-- <el-select v-model="jobForm.work" placeholder="请选择">
            <el-option v-for="item in jobTypesList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="人数">
          <el-input v-model="jobForm.number" type="number" placeholder="输入人数" @change="validateNumber"></el-input>
        </el-form-item>
        <el-form-item label="危险因素">
          <el-select v-model="jobForm.riskFactors" multiple filterable placeholder="输入/选择危险因素">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查阶段">
          <el-select v-model="jobForm.cheackPhase" multiple filterable placeholder="输入/选择检查阶段" style="width: 100%;">
            <el-option v-for="phase in checkPhaseOptions" :key="phase.value" :label="phase.label" :value="phase.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-table :data="filteredData" border style="width: 100%">
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column prop="dangerId" label="ID" width="180">
      </el-table-column> -->
          <!-- <el-table-column prop="dangerName" label="有害因素名称">
      </el-table-column> -->
          <!-- <el-table-column prop="checkPhase" sortable label="检查阶段">
      </el-table-column> -->
          <el-table-column prop="checkItem" sortable label="检查项目">
          </el-table-column>
          <!-- <el-table-column prop="nesessary" sortable label="是否必检">
      </el-table-column> -->
          <!-- <el-table-column prop="price" sortable label="价格(元)">
          </el-table-column> -->
        </el-table>
        <el-form-item>
          <el-button type="primary" @click="addJobEntry">确定</el-button>
          <el-button @click="showDialog = false">取消</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>




  </div>
</template>

<script>
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
export default {

  data() {
    return {
      showDialog: false,
      form: {
        companyName: '',
        phoneNumber: '',
        code: '',
      },
      jobTypes: [
      ],
      jobForm: {
        work: '',
        number: '',
        riskFactors: [],
        checkPhase: [],
        checkItem: '',
      },
      choose: '',
      choose_checkPhase: '',
      options: [{
        value: '1',
        label: '铅及其无机化合物'
      }, {
        value: '2',
        label: '四乙基铅'
      }, {
        value: '3',
        label: '汞及其无机化合物'
      }, {
        value: '4',
        label: '锰及其无机化合物'
      }, {
        value: '5',
        label: '镉及其无机化合物'
      }],
      checkPhaseOptions: [{
        value: '1',
        label: '上岗前'
      }, {
        value: '2',
        label: '在岗'
      }, {
        value: '3',
        label: '离岗'
      }
      ],
      tableData: [{
        dangerId: '1',
        dangerName: '铅及其无机化合物',
        checkItem: '尿常规',
        checkPhase: '上岗前',
        nesessary: '必检',
        price: '',
      }],
      stationForm: {
        name: '',
        work: '',
        number: '',
      },
      valid: false,
      fontloaded: false,
    };

  },
  computed: {
    // 根据选择的名字过滤数据
    filteredData() {
      const filtered = this.tableData.filter(tableData =>
        // (this.jobForm.riskFactors.length === 0 || this.jobForm.riskFactors.includes(tableData.dangerName)) &&
        // (this.jobForm.cheackPhase.length === 0 || this.jobForm.cheackPhase.includes(tableData.checkPhase))
        (this.jobForm.riskFactors.includes(tableData.dangerName)) &&
        (this.jobForm.cheackPhase.includes(tableData.checkPhase))
      );

      const uniqueItems = new Map();
      filtered.forEach(item => {
        if (!uniqueItems.has(item.checkItem)) {
          uniqueItems.set(item.checkItem, item);
        }
      });

      // 如果价格为0，显示免费
      return Array.from(uniqueItems.values()).map(item => {
        if (item.price === 0) {
          item.price = '免费';
        }
        return item;
      });

    }
  },
  created() {


  },
  methods: {
    validateNumber(value) {
      if (value < 0) {
        this.$message.error('人数不能为负数');
        this.jobForm.number = 0;
      }
    },
    addJobEntry() {
      this.jobTypes.push({
        work: this.jobForm.work,
        number: this.jobForm.number,
        riskFactors: this.jobForm.riskFactors,
        checkPhase: this.jobForm.cheackPhase,
        checkItem: this.filteredData.map(item => item.checkItem).join('、')
      });
      this.jobForm.work = '';
      this.jobForm.number = '';
      this.jobForm.riskFactors = [];
      this.jobForm.cheackPhase = [];
      this.jobForm.checkItem = '';
      this.showDialog = false;
    },
    async loadFont() {
      try {
        const font = await import('../../utils/STSONG-normal.js');
        this.font = font.default;
        console.log('Font loaded');
        this.fontloaded = true;
        console.log(this.fontloaded);
      } catch (error) {
        console.error('Failed to load font:', error);
      }
    },
    async generatePDF() {
      if (!this.fontloaded) {
        this.$message({
            message: "正在下载中，请稍等.",
            type: "warning",
          });
        await this.loadFont();

      }
      const doc = new jsPDF();
      doc.setFont('STSONG');
      doc.setFontSize(12);
      // 添加单位名称和联系方式
      doc.text(`单位名称: ${this.form.companyName}`, 14, 26);
      doc.text(`联系方式: ${this.form.phoneNumber}`, 14, 36);

      const tableColumn = ["工种", "人数", "危险因素", "检查阶段", "检查项目"];
      const tableRows = [];

      this.jobTypes.forEach(job => {
        const jobData = [
          job.work,
          job.number,
          job.riskFactors.join(", "),
          job.checkPhase.join(", "),
          job.checkItem
        ];
        tableRows.push(jobData);
      });
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 40,
        styles: {
          font: 'STSONG', //字体，如果不配置，表格中的中文仍会乱码
          // textColor: [0, 0, 0],
        },
      });

      doc.save('项目组合.pdf');
    },
    // 参考：element table相同数据行合并 https://blog.csdn.net/qq_42784165/article/details/109333050
    // 获取list
    getList() {
      this.$http({
        url: this.$http.adornUrl('/enterprise/hosipitalcheckrel/app/list'),
        method: 'get',
        params: this.$http.adornParams({
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data)
          this.tableData = data.page.list
          // Create a Set to store unique danger names
          const uniqueNames = new Set();
          const uniqueOptions = [];

          // Filter out duplicates
          data.page.list.forEach(item => {
            if (!uniqueNames.has(item.dangerName)) {
              uniqueNames.add(item.dangerName);
              uniqueOptions.push({
                value: item.dangerId,
                label: item.dangerName
              });
            }
          });

          this.options = uniqueOptions;
          console.log(this.options)

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    getCode(phone_number) {
      this.$http({
        url: this.$http.adornUrl("/sms/sendCode"),
        method: "get",
        params: this.$http.adornParams({ phone: phone_number }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log("看看数据");
          // console.log(data);
          this.valid = true;
          this.$message({
            message: "验证码已发至您的手机，请注意查收！",
            type: "success",
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    submitForm() {
      if (this.valid) {
        this.$message({
          message: "请你稍等，医院体检工作人员将尽快和您联系，对接体检详细情况。",
          type: "success",
        });
        // this.$http({
        //   url: this.$http.adornUrl(""),
        //   method: "post",
        //   data: this.$http.adornData({
        //     mobile: this.mobileForm.mobile,
        //     code: this.mobileForm.code,
        //     checkItems: this.filteredData
        //   }),
        // }).then(({ data }) => {
        //   if (data && data.code === 0) {
        //     this.$message({
        //       message: "提交成功",
        //       type: "success",
        //     });
        //   } else {
        //     this.$message.error(data.msg);
        //   }
        // });
      } else {
        console.log("error submit!!");
        this.$message({
          message: "请先获取验证码",
          type: "error",
        });
        return false;
      }

    },
  },
  mounted() {
    this.loadFont();
    this.getList()
  },
};
</script>

<style lang="scss">
// .brand-info__text {
//   text-align: center;
//   // margin: 0 0 22px 0;
//   font-size: 34px;
//   font-weight: 500px;
//   text-transform: uppercase;
// }
.text-home {
  text-align: center;
  font-size: 30px;
  color: firebrick;
  margin-bottom: 10px;
}

.regist-main {
  position: absolute;
  top: 50%;
  left: 50%;
  // padding: 150px 60px 180px;
  width: 550px;
  transform: translate(-50%, -50%);
  // min-height: 100%;
  // background-color: #fff;
}

.regist-title {
  font-size: 24px;
}

.regist-captcha {
  overflow: hidden;

  >img {
    width: 100%;
    cursor: pointer;
  }
}

.login-btn-submit {
  width: 100%;
  font-size: 18px;
  background-color: #428bca;
  border-color: #428bca;
}

.regist-btn-submit {
  width: 100%;
  font-size: 18px;
  // margin-top: 0px;
  // margin-top: 8px;
  background-color: #428bca;
  border-color: #428bca;
}

// .el-input .el-input__inner {
//   font-size: 18px
// }

.el-select-dropdown {
  max-width: 30%;
}

.el-select-dropdown__item {
  overflow: visible;
}
</style>
<style lang="scss" scoped>
::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}

/* 去除灰色横条 */
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/* 设置滑块颜色 */
::v-deep .el-tabs__active-bar {
  background-color: #428bca !important;
}

/* 设置滑块停止位置 */
::v-deep .el-tabs__active-bar.is-top {
  height: 37px;
  width: 125px !important;
  border-radius: 17px;
  top: 0px !important;
  left: -18px !important;
  position: absolute !important;
  z-index: 1;
}

/* 设置当前选中样式 */
::v-deep .el-tabs__item.is-active {
  color: #ffffff !important;
  z-index: 2;
}

/* 设置未被选中样式 */
::v-deep .el-tabs__item {
  padding: 0 20px !important;
  width: 104px;
  box-sizing: border-box;
  display: inline-block;
  position: relative !important;
  color: #000000 !important;
  font-size: 18px;
  z-index: 2;
}

::v-deep .custom-select .el-select-dropdown__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .option-label {
  display: block;
  max-width: 50px;
  /* Adjust the max-width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
